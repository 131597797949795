<template>
  <div style="background: #f2f2f2" class="min-h-screen">
    <div
      class="
        flex flex-col
        lg:flex-row
        gap-y-2
        lg:gap-y-0
        pb-5
        min-h-full
        lg:min-h-screen
      "
    >
      <div
        class="flex flex-col gap-y-6"
        :class="!doctors.length ? 'w-full lg:w-full' : 'w-full lg:w-9/12'"
      >
        <!-- <template v-if="$route.path.startsWith('/docteurs/actes/')">-->
        <template v-if="$route.params.acte">
          <filters-doctor-actes
            :acte="acte"
            :lengthData="totalItems"
            :mapDoctors="doctosHasGeo"
            class="
              border-0 border-b border-solid border-gray-300
              md:px-0
              lg:px-10
            "
        /></template>
        <template v-else>
          <filters-doctor
            :lengthData="totalItems"
            :mapDoctors="doctosHasGeo"
            class="
              border-0 border-b border-solid border-gray-300
              md:px-0
              lg:px-10
            "
          />
        </template>

        <div class="px-10 flex flex-col gap-y-4">
          <!-- If List has doctors -->
          <template v-if="doctors.length">
            <doctor-card
              v-for="(item, indexItem) in doctors"
              :key="indexItem"
              :id="`doctor-${item.objectID}`"
              :doctor="item"
            />
            <div class="flex justify-between items-center">
              <!-- Navs -->
              <div class="flex flex-row items-center gap-x-2">
                <span
                  class="font-EffraM text-lg capitalize"
                  style="color: #515151"
                >
                  {{ $t("patient.home") }}
                </span>
                <div class="flex items-center gap-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#515151"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7.5 15l5-5-5-5"
                    />
                  </svg>
                  <span
                    class="font-EffraM text-lg capitalize"
                    style="color: #515151"
                  >
                    {{ $route.params.city || "--" }}
                  </span>
                </div>
                <div class="flex items-center gap-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#515151"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7.5 15l5-5-5-5"
                    />
                  </svg>
                  <span
                    class="font-EffraM text-lg capitalize"
                    style="color: #515151"
                  >
                    {{ $route.params.query || acte.name || "--" }}
                  </span>
                </div>
              </div>
              <!-- Pagination -->
              <paginate
                :current="pagination.page"
                :total="totalItems"
                :per-page="8"
                @page-changed="onChangePage($event)"
              />
            </div>
          </template>
          <!-- If List has no doctors -->
          <template v-else>
            <empty>
              <img src="/svg/null_data.svg" class="max-h-600" />
            </empty>
          </template>
          <!-- End Condition -->
        </div>
      </div>
      <div class="relative lg:overflow-hidden lg:w-3/12" v-if="doctors.length">
        <map-based-algolia
          id="mapScroll"
          class="w-full hidden lg:block lg:absolute px-10 lg:px-0"
          :state="{
            items: {
              ...doctosHasGeo,
            },
          }"
        />
      </div>
    </div>

    <!-- City Seo -->
    <div
      v-if="seo"
      class="px-10 py-2 border-0 border-t border-solid border-gray-300 bg-white"
    >
      <div class="flex flex-col gap-y-3">
        <h1 class="text-3xl font-EffraM text-gray-700">{{ seo.title }}</h1>
        <div
          v-html="seo.html"
          class="font-EffraR text-lg"
          style="line-height: 210%"
        ></div>
      </div>
    </div>

    <!-- Footer -->
    <footer-layout :down-load-app="true"></footer-layout>
  </div>
</template>

<script>
  const doctorCard = () => import("./components/card/index.vue");
  const filtersDoctor = () => import("./components/filters/index.vue");
  const filtersDoctorActes = () => import("./components/filters/actes.vue");
  const footerLayout = () => import("@/views/home/layouts/footer.vue");
  const paginate = () => import("./pagination");
  import { EventBus } from "@/event-bus";
  const mapBasedAlgolia = () => import("./map");
  // Algolia
  import algoliasearch from "algoliasearch/lite";
  import algoliasearchHelper from "algoliasearch-helper";
  import { mapActions } from "vuex";

  export default {
    components: {
      filtersDoctor,
      footerLayout,
      doctorCard,
      mapBasedAlgolia,
      paginate,
      filtersDoctorActes,
    },
    watch: {
      "$route.query": {
        handler: function (newVal, oldVal) {
          this.onGetDoctors();
          this.$forceUpdate();
        },
        deep: true,
        immediate: true,
      },
    },
    data() {
      return {
        pagination: {
          page: 1,
          nbPages: 1,
        },
        totalItems: 0,
        seo: null,
        doctors: [],
        acte: {},
        doctorShortProfile: { data: {}, modal: false },
        queryAlgolia: algoliasearchHelper(
          algoliasearch("2BJUOM1BDB", "7906f008c0346b78f39d051fa293387f"),
          "dokma_doctors",
          {
            facets: ["cityName", "firstName", "lastName", "actes"],
            hitsPerPage: 15,
          }
        ),
      };
    },
    computed: {
      doctosHasGeo() {
        let filter = [];
        this.doctors.filter((doc) => {
          if (doc._geoloc) {
            filter.push(doc);
          }
        });
        return filter;
      },
    },

    destroyed() {
      window.removeEventListener("scroll", this.scrollMap);
    },

    mounted() {
      window.addEventListener("scroll", this.scrollMap);
      EventBus.$on("clickMarker", (ID) => {
        let queryElement = document.getElementById(`doctor-${ID}`);
        if (queryElement) {
          window.scrollTo({
            behavior: "auto",
            top: queryElement.offsetTop - 80,
          });
          queryElement.classList.add("animate__bounceIn");
          setTimeout(() => {
            queryElement.classList.remove("animate__bounceIn");
          }, 1500);
        }
      });
    },

    created() {
      this.onGetDoctors();
    },
    methods: {
      ...mapActions({
        getCitySeo: "doctor/GET_CITY_SEO",
      }),

      onChangePage(page) {
        this.queryAlgolia.setPage(page - 1);
        this.queryAlgolia.search();
      },

      scrollMap() {
        if (this.doctors.length) {
          let mapElement = document.getElementById("mapScroll");
          if (window.scrollY >= 85) {
            mapElement.style.top = `${window.scrollY - 79}px`;
            return;
          }
          mapElement.style.top = `0px`;
        }
      },

      async onGetDoctors() {
        this.$vs.loading();
        this.queryAlgolia = algoliasearchHelper(
          algoliasearch("2BJUOM1BDB", "7906f008c0346b78f39d051fa293387f"),
          "dokma_doctors",
          {
            facets: ["cityName", "firstName", "lastName", "languages", "actes"],
            hitsPerPage: 8,
          }
        );
        this.queryAlgolia.on("result", (event) => {
          this.pagination = {
            nbPages: event.results.nbPages,
            //page: event.results.page,
            page: event.results.page > 0 ? event.results.page + 1 : 1,
          };
          this.doctors = event.results.hits;
          this.totalItems = event.results.nbHits;
          this.$nextTick(() => {
            this.$vs.loading.close();
          });
        });
        let queryParams = this.queryAlgolia;
        let searchVars = [];
        if (this.$route.params.city) {
          searchVars.push(this.$route.params.city);
        }
        if (this.$route.params.languages) {
          this.$route.params.languages.forEach((language) =>
            searchVars.push(language)
          );
        }
        if (this.$route.params.query) {
          searchVars.push(this.$route.params.query);
        }
        if (this.$route.params.acte) {
          let { ok: okActe, data: dataActe } = await this.$store.dispatch(
            "doctor/GET_ACTE_BY_ID",
            { id: this.$route.params.acte }
          );
          if (okActe) {
            if (!dataActe.description) {
              dataActe.description = "";
            }
            this.acte = dataActe;
            searchVars.push(dataActe.name);
          }
        }
        queryParams.setQuery(searchVars.join(" ").replace("maroc", "")).search();
        if (this.$route.params.city) {
          this.getCitySeo({
            query: this.$route.params.query,
            city: this.$route.params.city,
            onData: (data) => {
              this.seo = data.data;
            },
          });
        }
      },

      onClickDoctor(doctorId) {
        if (!doctorId) return;
        this.$vs.loading();
        this.$store.dispatch("doctor/GET_BY_ID", {
          onData: ({ ok, data }) => {
            if (ok) {
              this.doctorShortProfile.data = data;

              this.$store.dispatch("agenda/GET_CATEGORIES", {
                onData: ({ ok: CatOk, data: DataOk }) => {
                  if (CatOk) {
                    this.doctorShortProfile.data.motifs = DataOk;
                    this.doctorShortProfile.modal = true;
                  }
                  this.$vs.loading.close();
                },
                data: { owner: doctorId },
              });
            }
          },
          id: doctorId,
        });
      },
    },
  };
</script>
